<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- إعادة تعيين كلمة المرور v1 -->
      <b-card class="mb-0">
        <!-- الشعار -->
        <b-link class="brand-logo">
        <b-img
          fluid
          :src="logoUrl"
          alt="Muqaym"
          style="height: 50px; width: auto"
        />
        <h2 class="brand-text text-primary ml-1">
          <!-- {{ $t('muqaym') }} -->
        </h2>
      </b-link>

        <b-card-title class="mb-1">
          إعادة تعيين كلمة المرور 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          يجب أن تكون كلمة المرور الجديدة مختلفة عن كلمات المرور المستخدمة سابقاً
        </b-card-text>

        <!-- النموذج -->
        <validation-observer ref="ResetPassword">
          <b-form
            method="POST"
            class="auth-reset-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- كلمة المرور -->
            <b-form-group
              label="كلمة المرور الجديدة"
              label-for="reset-password-new"
            >
              <validation-provider
                #default="{ errors }"
                name="كلمة المرور"
                vid="Password"
                rules="required|password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="reset-password-new"
                    v-model="password"
                    :type="password1FieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="reset-password-new"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password1ToggleIcon"
                      @click="togglePassword1Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- تأكيد كلمة المرور -->
            <b-form-group
              label-for="reset-password-confirm"
              label="تأكيد كلمة المرور"
            >
              <validation-provider
                #default="{ errors }"
                name="تأكيد كلمة المرور"
                rules="required|confirmed:Password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="reset-password-confirm"
                    v-model="cPassword"
                    :type="password2FieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="reset-password-confirm"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password2ToggleIcon"
                      @click="togglePassword2Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- زر الإرسال -->
            <b-button
              block
              type="submit"
              variant="primary"
            >
              تعيين كلمة المرور الجديدة
            </b-button>
          </b-form>
        </validation-observer>

        <p class="text-center mt-2">
          <b-link :to="{name:'auth-login-v1'}">
            <feather-icon icon="ChevronLeftIcon" /> العودة إلى تسجيل الدخول
          </b-link>
        </p>
      </b-card>
      <!-- /إعادة تعيين كلمة المرور v1 -->
    </div>
  </div>
</template>



<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import VuexyLogo from '@core/layouts/components/Logo.vue';
import {
  BCard, BCardTitle, BCardText, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BLink, BFormInput, BButton,
} from 'bootstrap-vue';
import { required } from '@validations';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    VuexyLogo,
    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      cPassword: '',
      password: '',
      // validation
      required,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
    };
  },
  created() {
    // Extract token and email from the URL when the component is created
    this.token = this.$route.query.token || null;
    // this.userEmail = this.$route.query.email || null;
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password';
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password';
    },
    async validationForm() {
      this.$refs.ResetPassword.validate().then(async (success) => {
        if (success) {
          try {
            // Send a POST request to the server to reset the password
            await this.$http.post('/api/reset-password', {
              // email: this.userEmail,
              token: this.token,
              password: this.password,
              password_confirmation: this.cPassword,
            });

            // Show success message and redirect to login page
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'تم إعادة ضبط كلمة المرور بنجاح',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            });
            this.$router.push({ name: 'auth-login' });
          } catch (error) {
            // Handle errors and show error message
            if (!error.response) console.log(error);

            // Assuming you have a validation error message in the response
            this.$refs.ResetPassword.setErrors(error.response.data.errors ?? []);

            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.response.data.message,
                text: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
